import { ActorSubclass } from "@dfinity/agent";
import {
  idlFactory as EvmRouteInterfaceFactory,
  _SERVICE,
} from "./candids/EvmRoute.did";
import BaseService from "./BaseService";
import { createActor } from "./candids";
import {
  AssetType,
  BridgeFee,
  BridgeStep,
  Chain,
  ChainID,
  GenerateTicketResult,
  OnBridgeParams,
  OnBurnParams,
  ServiceType,
  Ticket,
  TicketStatus,
  TicketStatusResult,
  Token,
  TxStatus,
} from "../types";
import {
  createPublicClient,
  createWalletClient,
  custom,
  getContract,
  http,
} from "viem";
import { Chain as EvmChain, isAddress } from "viem";
import { getServiceTypeFromChainId, isEvmChain } from "@utils/chains";
import { BTC_ICON, CHAIN_LIST, DEFAULT_TOKEN } from "@utils/constants";
import posthog from "posthog-js";

type EvmAddress = `0x${string}`;
export default class EvmRouteService extends BaseService {
  actor: ActorSubclass<_SERVICE>;
  network: EvmChain;

  constructor(chain: Chain) {
    super(chain);
    this.actor = createActor<_SERVICE>(
      chain.canister_id,
      EvmRouteInterfaceFactory,
    );
    this.network = chain.evm_chain as EvmChain;
  }

  async getTokenList(): Promise<Token[]> {
    try {
      if (
        this.chain.asset_type === AssetType.ckbtc &&
        this.chain.chain_id === ChainID.Core
      ) {
        const token: Token = {
          id: "0x51ccde9ca75d95bb55ece1775fcbff91324b18a6",
          name: "ckBTC",
          symbol: "ckBTC",
          decimals: 8,
          icon: BTC_ICON,
          balance: 0n,
          token_id: DEFAULT_TOKEN[ChainID.BitcoinckBTC],
          fee: 0n,
          chain_id: ChainID.Core,
        };

        return Promise.resolve([token]);
      }
      const tokenList = await this.actor.get_token_list();

      const tokens = await Promise.all(
        tokenList.map(async (t) => {
          try {
            const { decimals, icon, evm_contract, symbol, token_id } = t;
            const tokenContractAddress = evm_contract[0];
            if (!tokenContractAddress) {
              throw new Error("Missing token contract address");
            }
            const name = token_id.split("-")[2];
            return {
              decimals,
              symbol,
              name,
              token_id: token_id,
              id: tokenContractAddress,
              balance: 0n,
              icon: icon[0] ?? "",
              fee: 0n,
              chain_id: this.chain.chain_id,
            } as Token;
          } catch (error) {
            return null;
          }
        }),
      );
      if (this.chain.asset_type === AssetType.runes) {
        return tokens.filter(
          (t) => t !== null && t.token_id.startsWith("Bitcoin-runes-"),
        ) as Token[];
      }
      if (this.chain.asset_type === AssetType.brc20) {
        return tokens.filter(
          (t) => t !== null && t.token_id.startsWith("Bitcoinbrc20-"),
        ) as Token[];
      }
      return tokens.filter((t) => t !== null) as Token[];
    } catch (error) {
      return [];
    }
  }

  async fetchTokens(token_ids?: string[], address?: string): Promise<Token[]> {
    let tokenList = this.chain.token_list || [];
    if (Array.isArray(token_ids) && token_ids.length > 0) {
      tokenList = token_ids
        .map((id) => tokenList.find((r) => r.token_id === id))
        .filter((t) => !!t) as any;
    }
    if (!address) {
      return tokenList;
    }
    try {
      const publicClient = createPublicClient({
        chain: this.network,
        transport: http(),
      });
      const tokens = await Promise.all(
        tokenList.map(async (t) => {
          try {
            const tokenContractAddress = t.id;
            if (!tokenContractAddress) {
              throw new Error("Missing token contract address");
            }
            const contract = getContract({
              address: tokenContractAddress as EvmAddress,
              abi: [
                {
                  inputs: [
                    {
                      name: "_owner",
                      type: "address",
                    },
                  ],
                  name: "balanceOf",
                  outputs: [
                    {
                      name: "balance",
                      type: "uint256",
                    },
                  ],
                  payable: false,
                  stateMutability: "view",
                  type: "function",
                },
              ],
              client: publicClient,
            });
            let balance = 0n;
            if (address) {
              try {
                balance = await contract.read.balanceOf([
                  address as EvmAddress,
                ]);
              } catch (error) {}
            }
            return {
              ...t,
              balance,
            } as Token;
          } catch (error) {
            return null;
          }
        }),
      );
      return tokens.filter((t) => t !== null) as Token[];
    } catch (error) {
      return tokenList;
    }
  }

  getBridgeSteps(token?: Token): BridgeStep[] {
    return [
      {
        title: "Bridge",
        description: "Call port contract",
      },
    ];
  }

  async onBridge(params: OnBridgeParams): Promise<string> {
    try {
      const { token, sourceAddr, targetAddr, targetChainId, setStep, amount } =
        params;

      const publicClient = createPublicClient({
        chain: this.network,
        transport: http(),
      });
      const walletClient = createWalletClient({
        chain: this.network,
        transport: custom((window as any).ethereum),
      });

      const portContractAddr = this.chain.contract_address;

      if (!portContractAddr) {
        throw new Error("Missing port contract address");
      }

      // call port contract
      const portContract = getContract({
        address: portContractAddr as EvmAddress,
        abi: [
          {
            inputs: [
              {
                internalType: "string",
                name: "tokenId",
                type: "string",
              },
              {
                internalType: "string",
                name: "receiver",
                type: "string",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            name: "redeemToken",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "string",
                name: "dstChainId",
                type: "string",
              },
              {
                internalType: "string",
                name: "tokenId",
                type: "string",
              },
              {
                internalType: "string",
                name: "receiver",
                type: "string",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
              {
                internalType: "string",
                name: "memo",
                type: "string",
              },
            ],
            name: "transportToken",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "string",
                name: "target_chain_id",
                type: "string",
              },
            ],
            name: "calculateFee",
            outputs: [
              {
                internalType: "uint128",
                name: "",
                type: "uint128",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
        ],
        client: {
          public: publicClient,
          wallet: walletClient,
        },
      });

      if (this.chain.asset_type === AssetType.ckbtc) {
        const fee = await portContract.read.calculateFee([ChainID.sICP]);
        if (!this.chain.evm_chain) {
          throw new Error("Missing evm chain config");
        }
        const tx_hash = await portContract.write.redeemToken(
          [token.token_id, targetAddr, amount],
          {
            account: sourceAddr as EvmAddress,
            chain: this.network,
            value: fee,
          },
        );
        setStep && setStep(1);

        return tx_hash;
      } else {
        const [fee] = await this.actor.get_fee(targetChainId);
        const _fee = fee ?? BigInt(0);
        let tx_hash: string;
        const targetServiceType = getServiceTypeFromChainId(targetChainId);
        if (targetServiceType === ServiceType.Customs) {
          tx_hash = await portContract.write.redeemToken(
            [token.token_id, targetAddr, amount],
            {
              account: sourceAddr as EvmAddress,
              chain: this.network,
              value: _fee,
            },
          );
        } else {
          const memo = "";
          tx_hash = await portContract.write.transportToken(
            [targetChainId, token.token_id, targetAddr, amount, memo],
            {
              account: sourceAddr as EvmAddress,
              chain: this.network,
              value: _fee,
            },
          );
        }

        setStep && setStep(1);

        return tx_hash;
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes("User rejected the request")) {
          throw new Error("User rejected the transaction");
        }
      }
      throw error;
    }
  }

  async onBurn(params: OnBurnParams): Promise<string> {
    const { sourceAddr, token, amount, targetChainId } = params;
    const publicClient = createPublicClient({
      chain: this.network,
      transport: http(),
    });
    const walletClient = createWalletClient({
      chain: this.network,
      transport: custom((window as any).ethereum),
    });

    const portContractAddr = this.chain.contract_address;

    if (!portContractAddr) {
      throw new Error("Missing port contract address");
    }

    // call port contract
    const portContract = getContract({
      address: portContractAddr as EvmAddress,
      abi: [
        {
          inputs: [
            {
              internalType: "string",
              name: "tokenId",
              type: "string",
            },
            {
              internalType: "uint256",
              name: "amount",
              type: "uint256",
            },
          ],
          name: "burnToken",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
      ],
      client: {
        public: publicClient,
        wallet: walletClient,
      },
    });

    const [fee] = await this.actor.get_fee(targetChainId);
    const tx_hash = await portContract.write.burnToken(
      [token.token_id, amount],
      {
        account: sourceAddr as EvmAddress,
        chain: this.network,
        value: fee,
      },
    );
    return tx_hash;
  }

  async onMint({
    token,
    targetAddr,
    sourceAddr,
    targetChainId,
  }: OnBridgeParams): Promise<string> {
    try {
      const publicClient = createPublicClient({
        chain: this.network,
        transport: http(),
      });
      const walletClient = createWalletClient({
        chain: this.network,
        transport: custom((window as any).ethereum),
      });

      const portContractAddr = this.chain.contract_address;

      if (!portContractAddr) {
        throw new Error("Missing port contract address");
      }

      // call port contract
      const portContract = getContract({
        address: portContractAddr as EvmAddress,
        abi: [
          {
            inputs: [
              {
                internalType: "string",
                name: "tokenId",
                type: "string",
              },
              {
                internalType: "address",
                name: "receiver",
                type: "address",
              },
            ],
            name: "mintRunes",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
        ],
        client: {
          public: publicClient,
          wallet: walletClient,
        },
      });

      const [fee] = await this.actor.get_fee(targetChainId);

      const tx_hash = await portContract.write.mintRunes(
        [token.token_id, targetAddr as EvmAddress],
        {
          account: sourceAddr as EvmAddress,
          chain: this.network,
          value: fee,
        },
      );
      return tx_hash;
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes("User rejected the request")) {
          throw new Error("User rejected the transaction");
        }
      }
      throw error;
    }
  }

  async getTicketStatus(ticket_id: string): Promise<TicketStatusResult> {
    const res = await this.actor.mint_token_status(ticket_id);
    let status = Object.keys(res)[0] as TicketStatus;
    const statusValue = Object.values(res)[0];
    let tx_hash;
    if (status === TicketStatus.Finalized) {
      tx_hash = statusValue?.tx_hash;
    }
    return {
      status,
      tx_hash,
    };
  }

  static async getTxStatus(
    ticket: Pick<Ticket, "src_chain" | "ticket_id">,
  ): Promise<TxStatus> {
    if (!ticket.ticket_id) {
      return undefined;
    }
    try {
      const _network = CHAIN_LIST[ticket.src_chain].evmChain;
      if (_network) {
        const publicClient = createPublicClient({
          chain: _network,
          transport: http(),
        });

        const tx = await publicClient.getTransactionReceipt({
          hash: ticket.ticket_id as EvmAddress,
        });

        return tx.status;
      }
    } catch (error) {
      console.log("### getTxStatus error", error);
    }

    return undefined;
  }

  async generateTicket(ticket: Ticket): Promise<GenerateTicketResult> {
    const result = await this.actor.generate_ticket(ticket.ticket_id!);
    const finializedTicket = { ticket: { ...ticket, finalized: true } };
    if ("Ok" in result) {
      posthog.capture("ticket generate ok", {
        ...ticket,
        token_id: ticket.token,
      });
      return finializedTicket;
    }
    const error = result.Err;
    if (error === "duplicate request" || error === "call hub error") {
      return finializedTicket;
    }

    posthog.capture("ticket generate error", {
      ...ticket,
      token_id: ticket.token,
      error,
    });

    return { message: error, ticket: { ...ticket, finalized: false } };
  }

  static getTxHashLink(chain_id: ChainID, tx_hash?: string): string {
    if (isEvmChain(chain_id)) {
      const _network = CHAIN_LIST[chain_id].evmChain;
      if (_network) {
        return `${_network.blockExplorers?.default.url}/tx/${tx_hash}`;
      }
    }
    return "#";
  }

  static getTokenLink(chain_id: ChainID, id?: string): string {
    if (isEvmChain(chain_id)) {
      const _network = CHAIN_LIST[chain_id].evmChain;
      if (_network) {
        return `${_network.blockExplorers?.default.url}/token/${id}`;
      }
    }
    return "#";
  }

  async addToken(token: Token): Promise<void> {
    const walletClient = createWalletClient({
      chain: this.network,
      transport: custom((window as any).ethereum),
    });

    walletClient.watchAsset({
      type: "ERC20",
      options: {
        address: token.id,
        decimals: token.decimals,
        symbol: token.symbol,
        image: token.icon,
      },
    });
  }

  static validateAddress(addr: string): boolean {
    return isAddress(addr);
  }

  async getBridgeFee(
    targetChainId: ChainID,
    token?: Token,
  ): Promise<BridgeFee> {
    if (!this.chain.evm_chain) {
      throw new Error("Missing evm chain config");
    }

    if (this.chain.asset_type === AssetType.ckbtc) {
      const publicClient = createPublicClient({
        chain: this.network,
        transport: http(),
      });
      const walletClient = createWalletClient({
        chain: this.network,
        transport: custom((window as any).ethereum),
      });

      const portContractAddr = this.chain.contract_address;

      if (!portContractAddr) {
        throw new Error("Missing port contract address");
      }

      // call port contract
      const portContract = getContract({
        address: portContractAddr as EvmAddress,
        abi: [
          {
            inputs: [
              {
                internalType: "string",
                name: "target_chain_id",
                type: "string",
              },
            ],
            name: "calculateFee",
            outputs: [
              {
                internalType: "uint128",
                name: "",
                type: "uint128",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
        ],
        client: {
          public: publicClient,
          wallet: walletClient,
        },
      });

      const fee = await portContract.read.calculateFee([ChainID.sICP]);
      const { symbol, decimals } = this.chain.evm_chain.nativeCurrency;
      return {
        fee,
        symbol,
        decimals,
      };
    } else {
      const [fee] = await this.actor.get_fee(targetChainId);
      if (fee === undefined) {
        throw new Error("Failed to get redeem fee");
      }

      const { symbol, decimals } = this.chain.evm_chain.nativeCurrency;
      return {
        fee,
        symbol,
        decimals,
      };
    }
  }

  getFeeToken() {
    if (!this.chain.evm_chain) {
      throw new Error("Missing evm chain config");
    }

    const { symbol, decimals } = this.chain.evm_chain.nativeCurrency;
    return {
      symbol,
      decimals,
    };
  }
}
