import { Box, HStack, Skeleton, Text } from "@chakra-ui/react";
import { useTransferContext } from "@context/TransferContext";
import { formatUnits, readableNumber } from "@utils/format";
import { ChainID, Token } from "@types";
import { DEFAULT_TOKEN } from "src/utils/constants";
import { useTokenList } from "@context/TokenListProvider";

export default function TokenBalance() {
  return <TransferTokenBalance />;
}

function TransferTokenBalance() {
  const { token, onAmountChange } = useTransferContext();
  const { isLoading } = useTokenList();
  return (
    <BaseTokenBalance
      token={token}
      onAmountChange={onAmountChange}
      isLoading={isLoading}
      digits={6}
    />
  );
}

function BaseTokenBalance({
  token,
  onAmountChange,
  isLoading,
  digits = 3,
}: {
  token?: Token;
  onAmountChange: (amount: string) => void;
  isLoading: boolean;
  digits?: number;
}) {
  if (isLoading) {
    return <Skeleton w={81} h={23} isLoaded={false} />;
  }

  return (
    <HStack>
      <Text>
        {!!token
          ? readableNumber(formatUnits(token?.balance, token?.decimals), digits)
          : "-"}
      </Text>
      <Box bg="#008cd5" px={2} borderRadius={4} cursor="pointer">
        <Text
          color="white"
          fontSize={14}
          fontWeight={600}
          onClick={() => {
            if (token) {
              const txFee =
                token.chain_id === ChainID.sICP
                  ? BigInt(token.fee) * 2n
                  : BigInt(token.fee);
              if (token.balance > txFee) {
                onAmountChange(
                  formatUnits(token.balance - txFee, token?.decimals),
                );
              } else {
                onAmountChange("0");
              }
            }
          }}
        >
          Max
        </Text>
      </Box>
    </HStack>
  );
}
