import {
  idlFactory as ICPCustomsInterfaceFactory,
  _SERVICE,
} from "./candids/IcpCustoms.did";
import { ActorSubclass } from "@dfinity/agent";
import {
  BridgeFee,
  BridgeStep,
  Chain,
  ChainID,
  createActorFunctionType,
  GenerateTicketResult,
  OnBridgeParams,
  OnBurnParams,
  Ticket,
  TicketStatus,
  TicketStatusResult,
  Token,
} from "../types";
import { createActor } from "./candids";
import ICPService from "./ICPService";
import { Principal } from "@dfinity/principal";
import { formatGenerateTicketError } from "src/utils/helper";
import { IcrcLedgerCanister } from "@dfinity/ledger-icrc";

export const ICP_TOKEN_ID = "sICP-native-ICP";
export const ICRC_TOKEN_PREFIX = "sICP-icrc-";

export default class ICPCustomService extends ICPService {
  actor: ActorSubclass<_SERVICE>;

  constructor(chain: Chain) {
    super(chain);
    this.actor = createActor<_SERVICE>(
      chain.canister_id,
      ICPCustomsInterfaceFactory,
    );
  }

  async getTokenList(): Promise<Token[]> {
    try {
      const tokenList = await this.actor.get_token_list();

      const tokens = await Promise.all(
        tokenList.map(async (t) => {
          try {
            const tokenLedger = t.metadata[0]?.[1];
            if (!tokenLedger) {
              throw new Error("Invalid token id");
            }
            const { fee } = await this.fetchICPTokenBalanceAndFee(
              Principal.fromText(tokenLedger),
              "",
            );

            const token: Token = {
              id: tokenLedger,
              token_id: t.token_id,
              chain_id: this.chain.chain_id,
              decimals: t.decimals,
              symbol: t.symbol,
              name: t.name,
              icon: t.icon[0],
              balance: 0n,
              fee,
            };
            return token;
          } catch (error) {
            return null;
          }
        }),
      );
      return tokens.filter(
        (t) =>
          t !== null &&
          (t.token_id.startsWith(ICP_TOKEN_ID) ||
            t?.token_id.startsWith(ICRC_TOKEN_PREFIX)),
      ) as Token[];
    } catch (error) {
      return [];
    }
  }

  async fetchTokens(
    token_ids?: string[] | undefined,
    address?: string | undefined,
  ): Promise<Token[]> {
    let tokenList = this.chain.token_list || [];
    if (Array.isArray(token_ids) && token_ids.length > 0) {
      tokenList = token_ids
        .map((id) => tokenList.find((r) => r.token_id === id))
        .filter((t) => !!t) as any;
    }

    if (!address) {
      return tokenList;
    }
    try {
      const tokens = await Promise.all(
        tokenList.map(async (t) => {
          try {
            const { balance, fee } = await this.fetchICPTokenBalanceAndFee(
              Principal.fromText(t.id),
              address,
            );
            return {
              ...t,
              balance,
              fee,
            };
          } catch (error) {
            return null;
          }
        }),
      );

      return tokens.filter((t) => t !== null) as Token[];
    } catch (error) {
      return tokenList;
    }
  }

  getBridgeSteps(token?: Token): BridgeStep[] {
    if (token?.token_id.startsWith(ICRC_TOKEN_PREFIX)) {
      return [
        {
          title: "Approve",
          description: "Allow the bridge to transfer your tokens",
        },
        {
          title: "Bridge",
          description: "Generate Ticket",
        },
      ];
    }
    return [
      {
        title: "Deposit",
        description: "Transfer your tokens",
      },
      {
        title: "Bridge",
        description: "Generate Ticket",
      },
    ];
  }

  async onBridge(params: OnBridgeParams): Promise<string> {
    const {
      token,
      sourceAddr,
      targetAddr,
      targetChainId,
      setStep,
      amount,
      createActor,
      transfer,
    } = params;

    if (!createActor) {
      throw new Error("createActor is required");
    }

    let _amount = amount + token.fee;
    let fixedAmount = amount;
    if (token.balance < _amount) {
      fixedAmount = token.balance - token.fee * 2n;
      _amount = fixedAmount + token.fee;
    }
    const actor = await createActor<_SERVICE>(
      this.chain.canister_id,
      ICPCustomsInterfaceFactory,
      sourceAddr,
    );

    try {
      await this.prepareForGenerateTicket({
        token,
        userAddr: sourceAddr,
        amount: fixedAmount,
        targetChainId,
        setStep,
        transfer,
        createActor,
      });
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "User rejected") {
        } else {
        }
      }
    }

    const ticketResult = await actor.generate_ticket_v2({
      token_id: token.token_id,
      from_subaccount: [],
      target_chain_id: targetChainId,
      amount: _amount,
      receiver: targetAddr,
      memo: [],
    });

    if ("Err" in ticketResult) {
      throw new Error(
        `Failed to generate ticket: ${formatGenerateTicketError(ticketResult.Err)}`,
      );
    }
    setStep && setStep(2);

    return ticketResult.Ok.ticket_id;
  }

  onBurn(params: OnBurnParams): Promise<string> {
    throw new Error("Method not implemented.");
  }

  onMint(params: OnBridgeParams): Promise<string> {
    throw new Error("Method not implemented.");
  }

  async generateTicket(ticket: Ticket): Promise<GenerateTicketResult> {
    throw new Error("Method not implemented.");
  }

  async prepareForGenerateTicket({
    token,
    userAddr,
    amount,
    setStep,
    createActor,
  }: {
    token: Token;
    userAddr: string;
    amount: bigint;
    targetChainId: ChainID;
    setStep?: (step: number) => void;
    transfer?: (params: {
      to: string;
      amount: bigint;
    }) => Promise<number | bigint | undefined>;
    createActor?: createActorFunctionType;
  }) {
    if (
      token.token_id === ICP_TOKEN_ID ||
      token.token_id.startsWith(ICRC_TOKEN_PREFIX)
    ) {
      await this.onApprove({
        token,
        sourceAddr: userAddr,
        amount,
        createActor,
      });
    } else {
      throw new Error("Unsupported token");
    }
    setStep && setStep(1);
  }

  async getTicketStatus(ticket_id: string): Promise<TicketStatusResult> {
    const res = await this.actor.mint_token_status(ticket_id);
    const status = Object.keys(res)[0];
    return {
      status: status as TicketStatus,
    };
  }

  async getBridgeFee(
    targetChainId: ChainID,
    token?: Token,
  ): Promise<BridgeFee> {
    return Promise.resolve({
      fee: 0n,
      symbol: "ICP",
      decimals: 8,
    });
  }

  getFeeToken() {
    return {
      symbol: "ICP",
      decimals: 8,
    };
  }
}
