import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import CloseButtonForModal from "@components/common/CloseButtonForModal";
import FeeRateSelector from "@components/common/FeeRateSelector";
import { useHubContext } from "@context/OmnityHubContext";
import useFeeRate from "@hooks/useFeeRate";
import BitcoinCustomsService, {
  runesIndexerApi,
} from "@services/BitcoinCustomsService";
import ServiceFactory from "@services/Factory";
import { ChainID, EtchParams, HeightType } from "@types";
import {
  ICP_DECIMALS,
  MAX_DIVISIBILITY,
  MAX_RUNE_NAME_LENGTH,
  TOAST_ERROR_DURATION,
} from "@utils/constants";
import { formatUnits, readableNumber } from "@utils/format";
import { useICPWalletKit } from "@wallet-kits/icp-wallet-kit";
import { useEffect, useMemo, useState } from "react";
import { ICPConnectButton } from "./ConnectButton";
import useEtchings from "@hooks/useEtchings";
import request, { gql } from "graphql-request";

enum EtchStep {
  Preview,
  Processing,
}

const ModalTitle = {
  [EtchStep.Preview]: "Preview of Etch",
  [EtchStep.Processing]: "Processing",
};

export default function ConfirmEtch({
  params,
  heightType,
  mintable,
  onSubmitted,
}: {
  params: EtchParams;
  heightType: HeightType;
  mintable: boolean;
  onSubmitted: () => void;
}) {
  const [etchingFee, setEtchingFee] = useState<bigint>(0n);
  const [step, setStep] = useState(EtchStep.Preview);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [submitting, setSubmitting] = useState(false);
  const [exist, setExist] = useState<{ name: string; exist: boolean }>();
  const [estimating, setEstimating] = useState(false);

  const isValidName =
    params.rune_name &&
    /^[A-Z]+(•[A-Z]+)*$/.test(params.rune_name) &&
    params.rune_name.replace(/•/g, "").length >= 10 &&
    params.rune_name.replace(/•/g, "").length <= MAX_RUNE_NAME_LENGTH;

  const validation = useMemo(() => {
    if (!isValidName) {
      return {
        isValid: false,
        message: `Invalid rune name`,
      };
    }
    if (exist?.exist) {
      return { isValid: false, message: "Rune exists" };
    }
    if (
      params.divisibility &&
      (Number(params.divisibility) < 0 ||
        Number(params.divisibility) > MAX_DIVISIBILITY)
    ) {
      return {
        isValid: false,
        message: `Invalid divisibility`,
      };
    }
    if (
      heightType === HeightType.Absolute &&
      params.terms.height[0] &&
      params.terms.height[1]
    ) {
      const [start, end] = params.terms.height;
      if (Number(start) >= Number(end)) {
        return { isValid: false, message: "Invalid height range" };
      }
    }

    if (
      heightType === HeightType.Relative &&
      params.terms.offset[0] &&
      params.terms.offset[1]
    ) {
      const [start, end] = params.terms.offset;
      if (Number(start) >= Number(end)) {
        return { isValid: false, message: "Invalid offset range" };
      }
    }

    return { isValid: true, message: "" };
  }, [
    params.rune_name,
    params.divisibility,
    isValidName,
    exist?.exist,
    heightType,
    params.terms.height[0],
    params.terms.height[1],
    params.terms.offset[0],
    params.terms.offset[1],
  ]);

  const textColor = useColorModeValue("gray.800", "gray.100");
  const toast = useToast();
  const { chains } = useHubContext();
  const { feeRate } = useFeeRate();
  const { address, createActor } = useICPWalletKit();

  useEffect(() => {
    if (isValidName) {
      isRuneExist(params.rune_name).then(setExist);
    }
  }, [params.rune_name, isValidName]);

  useEffect(() => {
    if (isOpen && params.rune_name && chains.length) {
      const chain = chains.find((c) => c.chain_id === ChainID.Bitcoin);
      if (!chain) return;
      const _feeRate = feeRate[feeRate.selected];
      setEstimating(true);
      (ServiceFactory.createService(chain) as BitcoinCustomsService)
        .getEtchFee(_feeRate, params)
        .then((result) => {
          setEtchingFee(result);
          setEstimating(false);
        })
        .catch(() => {
          setEstimating(false);
        });
    }
  }, [params.rune_name, isValidName, feeRate.selected, chains.length, isOpen]);

  const { addEtching } = useEtchings();
  const onSubmit = async () => {
    try {
      setSubmitting(true);
      setStep(EtchStep.Processing);
      if (!params) {
        throw new Error("Invalid params");
      }
      if (!address) {
        throw new Error("Invalid address");
      }
      const _feeRate = feeRate[feeRate.selected];
      const chain = chains.find((c) => c.chain_id === ChainID.Bitcoin);
      if (!chain) {
        throw new Error("Invalid chain");
      }
      const service = ServiceFactory.createService(
        chain,
      ) as BitcoinCustomsService;
      const result = await service.onEtch({
        userAddr: address,
        feeRate: _feeRate,
        params,
        createActor,
        heightType,
        mintable,
      });
      addEtching({
        timestamp: Date.now(),
        id: result,
        name: params.rune_name,
        symbol: params.symbol,
      });
      setSubmitting(false);
      onSubmitted();
      _onClose();
      toast({
        description: `Etching ${params.rune_name}, stay tuned for the result`,
        status: "success",
        duration: TOAST_ERROR_DURATION,
        isClosable: true,
      });
    } catch (error) {
      setSubmitting(false);
      setStep(EtchStep.Preview);
      toast({
        description: (error as Error).message,
        status: "error",
        duration: TOAST_ERROR_DURATION,
        isClosable: true,
      });
    }
  };
  const _onClose = () => {
    onClose();
  };

  const data = [
    {
      key: "Name",
      value: params.rune_name,
    },
    {
      key: "Symbol",
      value: params.symbol,
    },
    {
      key: "Divisibility",
      value: params.divisibility ?? 0,
    },
    {
      key: "Premine",
      value: readableNumber(
        formatUnits(
          BigInt(params.premine ?? 0),
          Number(params.divisibility ?? 0),
        ),
      ),
    },
    {
      key: "Etching Fee",
      value: estimating ? (
        <Skeleton h={6} w={20} />
      ) : (
        `${readableNumber(formatUnits(etchingFee, ICP_DECIMALS), 6)} ICP`
      ),
    },
  ];
  return (
    <>
      <VStack w="100%">
        {!address ? (
          <ICPConnectButton forSubmit />
        ) : (
          <Button
            colorScheme={validation?.isValid ? "blue" : "gray"}
            w="98%"
            fontSize={24}
            py={8}
            borderRadius={8}
            disabled={!validation?.isValid}
            cursor={validation?.isValid ? "pointer" : "not-allowed"}
            onClick={() => {
              if (validation.isValid) {
                onOpen();
              }
            }}
          >
            {!!validation.message ? validation.message : "Confirm"}
          </Button>
        )}
      </VStack>

      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={_onClose}
      >
        <ModalOverlay />
        <ModalContent
          p={0}
          borderRadius={8}
          color={textColor}
          margin={{ base: 0 }}
          alignSelf={{ base: "flex-end", md: "center" }}
        >
          {step === EtchStep.Preview && <CloseButtonForModal />}
          <ModalHeader>{ModalTitle[step]}</ModalHeader>
          <ModalBody pb={6}>
            <VStack gap={{ base: 2, md: 1 }}>
              <VStack w="100%" gap={0} mb={4}>
                {data.map((item) => (
                  <HStack
                    key={item.key}
                    w="100%"
                    justifyContent="space-between"
                    borderBottomWidth={0.5}
                    borderBottomColor="gray"
                    py={2}
                  >
                    <Text>{item.key}</Text>
                    <Text fontWeight={600}>{item.value}</Text>
                  </HStack>
                ))}
              </VStack>
              {step === EtchStep.Preview && <FeeRateSelector />}

              <Button
                colorScheme="blue"
                w="98%"
                fontSize={24}
                py={8}
                borderRadius={8}
                isLoading={submitting}
                loadingText="Etching"
                onClick={onSubmit}
              >
                Confirm
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

async function isRuneExist(name: string) {
  try {
    const document = gql`
      {
        runes(where: { spaced_rune: { _eq: "${name}" } }) {
          etching
          id
        }
      }
    `;
    const result = await request(`${runesIndexerApi}/v1/graphql`, document);
    return { name, exist: (result as any).runes.length > 0 };
  } catch (error) {
    return { name, exist: false };
  }
}
